.background-image {
    background-image: url('../resource/背景组.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.root-contain{
    width: 95vw;
    height: calc(95vw * 2.7);
    margin-left: auto;
    margin-right: auto;
    padding-top: 3.5%; /* 上方内边距为父元素宽度的5% */
    padding-bottom: 3.5%; /* 下方内边距为父元素宽度的5% */
    padding-left: 5%; /* 左侧内边距为父元素宽度的10% */
    padding-right: 5%; /* 右侧内边距为父元素宽度的10% */
}

.word-top-container{
    width: 92%; /* 容器宽度占页面宽度的80% */
    margin: 3.3% auto;
}

.content{
    width: 100%; /* 容器宽度占页面宽度的100% */
    color: white; 
}

.content2{
    width: 100%; /* 容器宽度占页面宽度的100% */
    color: black;
    line-height: 1;
    font-weight: 500; /* 设置文字加粗 */
}

.content3{
    width: 100%; /* 容器宽度占页面宽度的100% */
    color: black; 
    line-height: 1;
    font-weight: 500; /* 设置文字加粗 */
    color: #2426AD; /* 设置文字颜色为红色，使用十六进制值 */
}

.scroll-container-large{
    margin-top: 12%;
    background-color: rgba(0, 255, 255, 0.1);
    height: 20%;
    border-radius: 20px;
    padding-top: 2%; /* 上方内边距为父元素宽度的5% */
    padding-bottom: 2%; /* 下方内边距为父元素宽度的5% */
    padding-left: 4%; /* 左侧内边距为父元素宽度的10% */
    padding-right: 2%; /* 右侧内边距为父元素宽度的10% */
}

.scroll-container {
    height: 100%;
    overflow-y: scroll;
    border: none;
    background-color: rgba(69, 20, 20, 0);
}

.scroll-container::-webkit-scrollbar {
    width: 0.6vw; /* 设置滚动条宽度 */
}
  
.scroll-container::-webkit-scrollbar-track {
    background: #14295E; /* 设置滚动条轨道的背景色 */
    border-radius: 12px;
}
  
.scroll-container::-webkit-scrollbar-thumb {
    background: #60719F; /* 设置滚动条滑块的背景色 */
    border-radius: 12px; /* 设置滚动条滑块的圆角 */
}
  
.scroll-container::-webkit-scrollbar-thumb:hover {
    background: #555; /* 设置鼠标悬停时滚动条滑块的背景色 */
}

.button-style{
    height: 18vh;
    width: 18vh;
    border-radius: 50%;
    background-color: #4CAF50;
    color: white;
}

.time-contain{
    margin-top: 7%;
    height: 5vw;
    width: 14vw;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(160, 37, 37, 0);
}

.reset{
    height: 10vw;
    width: 6vw;
    margin-top: 5vw;
    display: flex;
    flex-direction: column; /* 垂直排列 */
    align-items: center; /* 垂直居中 */  
    justify-content: center; /* 水平居中 */  
    text-align: center;
    cursor: pointer;
}

.reset-contain{
    height: 5vw;
    width: 6vw;
    background-color: rgb(160, 37, 37, 0);
    background-image: url('../resource/重置3.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url('../resource/加载.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px;
    width: 30vw; /* 设置弹窗的宽度 */
    height: 30vw; /* 设置弹窗的高度 */
    border-radius: 10%;
  }


.submit{
    height: 10vw;
    width: 6vw;
    margin-top: 5vw;
    display: flex;
    flex-direction: column; /* 垂直排列 */
    align-items: center; /* 垂直居中 */  
    justify-content: center; /* 水平居中 */  
    text-align: center;
    cursor: pointer;
}

.submit-contain{
    height: 5vw;
    width: 5vw;
    background-color: rgb(160, 37, 37, 0);
    background-image: url('../resource/303正确、完成-圆框.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.rotate{
    animation: spin 2s linear infinite;
}

.loading-contain{
    height: 5vw;
    width: 5vw;
    background-color: rgb(160, 37, 37, 0);
    background-image: url('../resource/submitloading.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    animation: spin 2s linear infinite;
}

.image-container {
    margin-top: 2%;
    display: flex;
    background-color: rgb(160, 37, 37, 0);
    align-items: center; /* 垂直居中 */  
    justify-content: center; /* 水平居中 */  
    gap: 6vw; /* 设置项目之间的间距 */
}

.image-contain{
    height: 20vw;
    width: 20vw;
    border-radius: 50%;
    background-color: rgb(160, 37, 37, 0);
    background-image: url('../resource/按钮.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    display: flex;  
    align-items: center; /* 垂直居中 */  
    justify-content: center; /* 水平居中 */  
}

.sub-image-contain{
    height: 9vw;
    width: 9vw;
    background-color: rgba(255, 255, 255, 0);
    background-image: url('../resource/麦克风,声音,录音,录制声音.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    
}

.sub-image-contain-loading{
    height: 9vw;
    width: 9vw;
    background-color: rgba(255, 255, 255, 0);
    background-image: url('../resource/loading.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    
}

.click-contain{
    height: 2vh;
    text-align: center;
}

.ins-contain{
    margin-top: 16%;
    height: 10vh;
    width: 50vw;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(160, 37, 37, 0);
    background-image: url('../resource/ins.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.ins-content-contain{
    margin-top: 4%;
    height: calc(80vw / 1.30);
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(160, 37, 37, 0);
    background-image: url('../resource/ins_content.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.pop-contain{
    background-color: rgb(255,255, 255, 1);
    display: flex;
    flex-direction: column; /* 垂直排列 */
    align-items: center; /* 垂直居中 */  
    justify-content: center; /* 水平居中 */  
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw; /* 设置弹窗的宽度 */
    height: 40vw; /* 设置弹窗的高度 */
    border-radius: 10%;
}

.pop-loading{
    height: 16vw;
    width: 16vw;
    margin-top: 1vw;
    background-image: url('../resource/加载.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;  
    align-items: center; /* 垂直居中 */  
    justify-content: center; /* 水平居中 */  
}

.pop-loading-sandglass{
    height: 10vw;
    width: 10vw;
    background-image: url('../resource/加载1.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.pop-text-contain{
    width: 26vw;
    margin-top: 2vw;
    text-align: center;
    line-height: 1;
}

.pop-text-click-contain{
    width: 28vw;
    margin-top: 1vw;
    text-align: center;
    cursor: pointer;
}

.pop-clap{
    height: 20vw;
    width: 30vw;
    margin-top: 0vw;

    background-image: url('../resource/鼓掌.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}


.pop-close{
    height: 2vw;
    width: 2vw;
    margin-left: 24vw;
    margin-top: -2.6vw;
    background-image: url('../resource/关闭.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}


.upload-fail-contain{
    background-color: rgb(255,255, 255, 1);
    display: flex;
    flex-direction: column; /* 垂直排列 */
    align-items: center; /* 垂直居中 */  
    justify-content: center; /* 水平居中 */  
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20vw; /* 设置弹窗的宽度 */
    height: 20vw; /* 设置弹窗的高度 */
    border-radius: 10%;
}

.upload-fail-text-contain{
    width: 18vw;
    margin-top: 2vw;
    text-align: center;
    line-height: 1;
}

.upload-fail-pop-close{
    height: 2vw;
    width: 2vw;
    margin-left: 15vw;
    margin-top: -6vw;
    background-image: url('../resource/关闭.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}



p {
    font-size: 4vw; /* 使用vw单位设置字体大小，让它随着容器宽度的变化而变化 */
}

p1 {
    font-size: 2vw; /* 使用vw单位设置字体大小，让它随着容器宽度的变化而变化 */
}
