.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 新增的图片样式 */
.my-image {
  max-width: 100%; /* 设置图片的最大宽度为其父元素的100% */
  height: auto; /* 让图片的高度自动调整，以保持宽高比 */
  border-radius: 10px; /* 添加圆角边框 */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
}

.background-image {
  background-image: url('./resource/背景组.png');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  z-index: -1;
}

.top-background-image {
  background-image: url('./resource/弹框.png');
  background-size: cover;
  background-position: center;
  min-height: 80vh;
  z-index: 0;
}
 p{
  font-size: 4vw;
 }